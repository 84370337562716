import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMain = _resolveComponent("FilterMain")!

  return (_ctx.authStore.user.allowed_companies?.length > 1 || _ctx.authStore.isSuperUser)
    ? (_openBlock(), _createBlock(_component_FilterMain, {
        key: 0,
        id: "main_view-stores-list"
      }))
    : _createCommentVNode("", true)
}